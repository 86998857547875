import { useEffect, useState } from "react";
import "./input.css";
import n5 from "./n5.json";

function App() {
  const [words, setWords] = useState(n5);
  const [totalWords, setTotalWords] = useState("");
  const [currentWord, setCurrentWord] = useState("");

  const random = Math.floor((Math.random() * words.length) | 0);

  const [showHiragana, setShowHiragana] = useState(false);
  const [showMeaning, setShowMeaning] = useState(false);
  const [counterYes, setCounterYes] = useState(0);
  const [counterNo, setCounterNo] = useState(0);

  useEffect(() => {
    setCurrentWord(words[random]);
    setTotalWords(n5.length);
  }, [words]);

  const clickYes = (id) => {
    setCounterYes((count) => count + 1);

    const updateWords = [...words].filter((word) => word.id !== id);
    setWords(updateWords);

    if (words.length > 0) {
      setCurrentWord(words[random]);
    }
  };

  const clickNo = (id) => {
    setCounterNo((count) => count + 1);

    const updateWords = [...words].filter((word) => word.id !== id);
    setWords(updateWords);

    if (words.length > 0) {
      setCurrentWord(words[random]);
    }
  };

  const restart = () => {
    setWords(n5);

    setCounterYes(0);
    setCounterNo(0);
    setShowHiragana(false);
    setShowMeaning(false);
    setCurrentWord(words[random]);
  };

  return (
    <div className="App">
      <div className="flex flex-col h-screen items-center justify-center bg-indigo-50 px-4">
        <div className="max-w-sm overflow-hidden rounded-xl bg-white shadow-md">
          <main className="flex flex-col relative text-center h-64 justify-center bg-sky-200">
            <span className="word text-adjust font-extrabold whitespace-nowrap">
              {words.length > 0
                ? currentWord.word
                : "おめでとう! 終わりました!"}
            </span>
            {showHiragana && (
              <span className="text-3xl text-slate-700">
                {currentWord.hiragana}
              </span>
            )}
            {showMeaning && (
              <span className="text-2xl text-blue-700">
                {currentWord.meaning}
              </span>
            )}
            <span className="absolute bottom-0 right-0 pr-2 pb-2">
              {words.length}/{totalWords}
            </span>
          </main>
          <section>
            <div className="flex p-5 border-b border-gray-300 text-gray-700">
              <div className="flex-1 inline-flex items-center">
                😓
                <p>
                  <span className="text-gray-900 font-bold">{counterNo} </span>
                  わからない
                </p>
              </div>
              <div className="flex-1 inline-flex items-center">
                🤓
                <p>
                  <span className="text-gray-900 font-bold">{counterYes} </span>
                  わかります
                </p>
              </div>
            </div>
          </section>
          <div className="p-5">
            {/* <p class="text-medium mb-5 text-gray-700">
              Well, thanks to Minori for teaching me Japanese. I really
              appreciate your efforts and faith on me. Love you
            </p> */}
            <nav className="mb-2 space-x-4">
              <button
                className="inline-block rounded-md bg-red-600 px-10 py-2 text-red-100 hover:bg-red-500 hover:shadow-md duration-75"
                onClick={() =>
                  words.length > 0 ? clickNo(currentWord.id) : ""
                }
              >
                わからない
              </button>
              <button
                className="inline-block rounded-md bg-green-600 px-12 py-2 text-green-100 hover:bg-green-500 hover:shadow-md duration-75"
                onClick={() => clickYes(currentWord.id)}
              >
                わかります
              </button>
            </nav>

            <nav className="mb-2 space-y-4">
              <button
                className="w-full rounded-md bg-indigo-600  py-2 text-indigo-100 hover:bg-indigo-500 hover:shadow-md duration-75"
                onClick={() => setShowHiragana(!showHiragana)}
              >
                ひらがな
              </button>
            </nav>

            <nav className="mb-2 space-y-4">
              <button
                className="w-full rounded-md bg-indigo-600  py-2 text-indigo-100 hover:bg-indigo-500 hover:shadow-md duration-75"
                onClick={() => setShowMeaning(!showMeaning)}
              >
                いみ
              </button>
            </nav>
          </div>
        </div>
        <div
          className="flex flex-col pt-2 hover:text-indigo-800 hover:font-bold hover:cursor-pointer"
          onClick={restart}
        >
          またはじめる
        </div>
      </div>
    </div>
  );
}

export default App;
